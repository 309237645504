import { useState } from 'react'
import {Capacitor} from "@capacitor/core";

export function useLocalOrSessionState(localItem: string): any {
    const storage = Capacitor.isNativePlatform() ? localStorage : sessionStorage;

    const [loc, setState] = useState(storage.getItem(localItem));

    const setLoc = (newItem: any) => {
        storage.setItem(localItem, newItem);
        setState(newItem);
    };

    return [loc, setLoc]
}

export function removeLocalOrSessionStateItem(localItem: string) {
    const storage = Capacitor.isNativePlatform() ? localStorage : sessionStorage;

    storage.removeItem(localItem);
}

export function useLocalState(localItem: string) : any {
    const [loc, setState] = useState(localStorage.getItem(localItem));
    
    const setLoc = (newItem: any) => {
        localStorage.setItem(localItem, newItem);
        setState(newItem);
    };

    return [loc, setLoc]
}

export function removeLocalStateItem(localItem: string) {
    localStorage.removeItem(localItem);
}

export function clearLocalState() {
    let favs = localStorage.getItem("favoriteShops");
    localStorage.clear();

    if (!Capacitor.isNativePlatform()) {
        sessionStorage.clear();
    }

    localStorage.setItem("favoriteShops", favs !== null ? favs : JSON.stringify([]));
}