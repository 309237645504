import {useState, useEffect} from 'react';
import {Badge} from "@awesome-cordova-plugins/badge";

const useForum = (shop: any) => {
    const [forumMessageCount, setForumMessageCount] = useState<number>(0);
    const [forumBadgeSupported, setForumBadgeSupported] = useState<boolean | null>(null);

    useEffect(() => {
        if (forumBadgeSupported === null) {
            Badge.isSupported().then(
                (status) => {
                    if (status === false) {
                        console.log('Badges not supported');
                        setForumBadgeSupported(false);
                        // skipping, badge not supported
                    } else {
                        console.log('Badges supported');
                        setForumBadgeSupported(true);
                    }
                },
                (error) => {
                    console.log('Badges not supported');
                    setForumBadgeSupported(false);
                    // skipping, badge not supported
                }
            );
        }
    }, [])

    useEffect(() => {
        if (forumBadgeSupported === true) {
            if (shop.forumEnabled === true) {
                console.log('Setting badge to ' + forumMessageCount);
                Badge.set(forumMessageCount);
            } else {
                console.log('Clear badge');
                Badge.set(0);
            }
        }
    }, [forumBadgeSupported, forumMessageCount, shop.forumEnabled])

    return {
        forumMessageCount,
        setForumMessageCount
    };
}

export default useForum;