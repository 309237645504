import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {SplashScreen} from '@capacitor/splash-screen';
import * as serviceWorker from './serviceWorker';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import {setupConfig} from '@ionic/react';

setTimeout(() => {
    SplashScreen.hide();
}, 300);

setupConfig({
    mode: 'ios'
})

localStorage.setItem("checkVersion", "true");
localStorage.setItem("checkValidation", "true");

ReactDOM.render(<App />, document.getElementById('root'));
defineCustomElements(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
