import {useEffect, useState} from 'react';
import API from "../api/Calls";

const useShop = (): ShopInterface => {
    const [shopId, setShopId] = useState<number | null>(null);
    const [currentShopId, setCurrentShopId] = useState<number | null>(null);
    const [forumEnabled, setForumEnabled] = useState<boolean>(false);
    const [contactListEnabled, setContactListEnabled] = useState<boolean>(false);
    const [customerCreditEnabled, setCustomerCreditEnabled] = useState<boolean>(false);
    const [shopData, setShopData] = useState<any>(null);

    useEffect(() => {
        if (shopId === null || isNaN(shopId)) {
            console.log('setShopId called with NULL or NaN value');
            if (shopData !== null || shopId !== null || currentShopId !== null) {
                clearShop();
            }
        } else {
            if (currentShopId === null && shopId !== currentShopId) {
                console.log('Shop ID set to ' + shopId);
                if (shopId !== null) {
                    loadShop(shopId);
                }
            } else if (currentShopId !== null && shopId !== currentShopId) {
                console.log('Shop ID changed to ' + shopId + ' (prev: ' + currentShopId + ')');
                if (shopId !== null) {
                    loadShop(shopId);
                }
            } else {
                console.log('setShopId called but no changes: ' + shopId);
            }
        }
    }, [shopId])

    function loadShop(shopId: number) {
        API.shops.getOne(shopId, true).then((response: any) => {
            setCurrentShopId(shopId);
            processShopsData(response.data);
        }, (err: any) => {
            console.log(err);
        })
    }

    function processShopsData(data: any) {
        if (data !== null) {
            setCurrentShopId(data.id);
            setShopId(data.id);
            setShopData(data);
            setContactListEnabled(data.moduleContactList);
            setForumEnabled(data.forumEnabled);
            setCustomerCreditEnabled(data.moduleCustomerCredit);
        } else {
            setCurrentShopId(null);
            setShopId(null);
            setShopData(null);
            setContactListEnabled(false);
            setForumEnabled(false);
            setCustomerCreditEnabled(false);
        }
    }

    function reloadShop(data?: any) {
        if (data !== undefined) {
            processShopsData(data);
        } else if (shopId !== null) {
            loadShop(Number(shopId));
        }

        return true;
    }

    function clearShop() {
        console.log('clearShop called');
        processShopsData(null);
        return true;
    }

    return {
        shopId,
        shopData,
        setShopId,
        forumEnabled,
        setForumEnabled,
        contactListEnabled,
        setContactListEnabled,
        customerCreditEnabled,
        setCustomerCreditEnabled,
        reloadShop
    };
}

export default useShop;

export interface ShopInterface {
    shopId: number | null,
    shopData: any,
    setShopId: Function,
    forumEnabled: boolean,
    setForumEnabled: Function,
    contactListEnabled: boolean,
    setContactListEnabled: Function,
    customerCreditEnabled: boolean
    setCustomerCreditEnabled: Function,
    reloadShop: Function
}

export const ShopInit: ShopInterface = {
    shopId: null,
    shopData: null,
    setShopId: () => null,
    forumEnabled: false,
    setForumEnabled: () => null,
    contactListEnabled: false,
    setContactListEnabled: () => null,
    customerCreditEnabled: false,
    setCustomerCreditEnabled: () => null,
    reloadShop: () => null,
}