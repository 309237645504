import {useEffect, useState} from 'react';
import API from "../api/Calls";
import {useLocalState, removeLocalOrSessionStateItem, useLocalOrSessionState} from "./LocalState";

const useCustomer = (shop: any): CustomerInterface => {
    const [oldCustomerId, setOldCustomerId] = useLocalState('currentCustomerId');

    const [shopCustomerIdStore, setShopCustomerIdStore] = useLocalOrSessionState('shopCustomerIdStore');
    const [customerId, setCustomerId] = useState<number | null>(null);
    const [currentCustomerId, setCurrentCustomerId] = useState<number | null>(null);
    const [contactListAccess, setContactListAccess] = useState<boolean>(false);
    const [customerData, setCustomerData] = useState<any>(null);

    const [customerLocationId, setCustomerLocationId] = useState<number | null>(null);

    const [customerSessionTokenStore, setCustomerSessionTokenStore] = useLocalOrSessionState('customerSessionTokenStore');
    const [customerSessionToken, setCustomerSessionToken] = useLocalOrSessionState('currentSessionToken')

    const [authenticated, setAuthenticated] = useState<boolean | null>(null);

    let shopCustomerIds = JSON.parse(shopCustomerIdStore) ?? {};
    let customerSessionTokens = JSON.parse(customerSessionTokenStore) ?? {};

    useEffect(() => {
        shopCustomerIds = JSON.parse(shopCustomerIdStore) ?? {};
    }, [shopCustomerIdStore])

    useEffect(() => {
        console.log('authenticated changed: ' + authenticated);
    }, [authenticated])

    useEffect(() => {
        customerSessionTokens = JSON.parse(customerSessionTokenStore) ?? {};
    }, [customerSessionTokenStore])

    useEffect(() => {
        if (shop.shopId !== undefined) {
            if (shop.shopId === null || isNaN(shop.shopId)) {
                console.log('shopId changed in customer with NULL or NaN value');
                if (authenticated || customerData !== null || customerId !== null || currentCustomerId !== null || customerLocationId !== null) {
                    clearCustomer();
                }
            } else {
                migrateOldSystem();

                console.log('Check authentication for shop ' + shop.shopId);

                if (shopCustomerIds['s' + shop.shopId] !== undefined && shopCustomerIds['s' + shop.shopId] !== null) {
                    if (currentCustomerId === shopCustomerIds['s' + shop.shopId]) {
                        console.log('Nothing changed for shop ' + shop.shopId + ' customer ' + shopCustomerIds['s' + shop.shopId]);
                    } else {
                        console.log('Load shop ' + shop.shopId + ' customer ' + shopCustomerIds['s' + shop.shopId]);
                        loadCustomer(shopCustomerIds['s' + shop.shopId]);
                    }
                } else if (customerData !== null || customerId !== null || currentCustomerId !== null || customerLocationId !== null) {
                    console.log('No customer found to load for shop ' + shop.shopId + ', going to clear current object');
                    setAuthenticated(false);
                    clearCustomer();
                } else {
                    console.log('No customer found to load for shop ' + shop.shopId + ' and none loaded');
                    setAuthenticated(false);
                }
            }
        }
    }, [shop.shopId])

    useEffect(() => {
        if (currentCustomerId !== null && customerId !== currentCustomerId) {
            setAuthenticated(null);
            console.log('Customer ID set or changed to ' + customerId + ' (prev: ' + currentCustomerId + ')');
            setContactListAccess(false);
            if (customerId !== null) {
                loadCustomer(customerId);
            }
        }
    }, [customerId])

    function migrateOldSystem() {
        if (oldCustomerId !== null) {
            console.log('Migrate customer ID ' + oldCustomerId + ' and token ' + customerSessionToken);
            addCustomerIdAndShopIdRelation(oldCustomerId, customerSessionToken);
            removeLocalOrSessionStateItem('currentCustomerId');
        }
    }

    function loadCustomer(newCustomerId: number) {
        setAuthenticated(null);
        let newCustomerSessionToken = '';

        if (customerSessionTokens['c' + newCustomerId] !== undefined && customerSessionTokens['c' + newCustomerId] !== null) {
            console.log('Set session token to ' + customerSessionTokens['c' + newCustomerId] + ' for customer ' + newCustomerId);
            newCustomerSessionToken = customerSessionTokens['c' + newCustomerId];

            setCustomerSessionToken(newCustomerSessionToken);

            API.customer.getOne(newCustomerSessionToken, newCustomerId, true).then((response: any) => {
                processCustomerData(response.data);
            }, (err: any) => {
                console.log(err.response?.data)
            });
        } else {
            processCustomerData(null);
        }
    }

    function processCustomerData(data: any) {
        if (data !== null) {
            setContactListAccess(data.showContactList ?? false);

            setCustomerLocationId(data.locationId);

            addCustomerIdAndShopIdRelation(data.id);

            setCustomerData(data);
            setCurrentCustomerId(data.id);
            setCustomerId(data.id);
            setAuthenticated(true);
        } else {
            setAuthenticated(false);
            setCustomerId(null);
            setCurrentCustomerId(null);
            setCustomerData(null);

            setContactListAccess(false);
            setCustomerLocationId(null);
        }
    }

    function addSessionIdAndCustomerIdRelation(newCustomerId: number, newCustomerSessionToken: string) {
        customerSessionTokens['c' + newCustomerId] = newCustomerSessionToken;
        setCustomerSessionTokenStore(JSON.stringify(customerSessionTokens));

        return true;
    }

    function addCustomerIdAndShopIdRelation(newCustomerId: number, newCustomerSessionToken?: string) {
        if (shop.shopId !== undefined) {
            shopCustomerIds['s' + shop.shopId] = newCustomerId;
            setShopCustomerIdStore(JSON.stringify(shopCustomerIds));
        }

        if (newCustomerSessionToken !== undefined) {
            addSessionIdAndCustomerIdRelation(newCustomerId, newCustomerSessionToken);
        }

        return true;
    }

    function reloadCustomer(data?: any) {
        if (data !== undefined) {
            console.log('Reloading customer data with specified data');
            processCustomerData(data);
        } else if (customerId !== null) {
            console.log('Reloading customer ' + customerId + ' data');
            loadCustomer(customerId);
        }

        return true;
    }

    function logoutAndClearCustomer() {
        setAuthenticated(null);

        if (customerSessionTokens['c' + customerId] !== undefined) {
            delete customerSessionTokens['c' + customerId];
        }
        setCustomerSessionTokenStore(JSON.stringify(customerSessionTokens));

        if (shopCustomerIdStore['s' + shop.shopId] !== undefined) {
            delete shopCustomerIdStore['s' + shop.shopId];
        }
        setCustomerSessionTokenStore(JSON.stringify(customerSessionTokens));
        removeLocalOrSessionStateItem('currentSessionToken');

        console.log('A2');
        return clearCustomer();
    }

    function clearCustomer() {
        console.log('clearCustomer called');
        processCustomerData(null);
        return true;
    }

    return {
        customerId,
        customerData,
        contactListAccess,
        reloadCustomer,
        clearCustomer,
        addSessionIdAndCustomerIdRelation,
        addCustomerIdAndShopIdRelation,
        customerSessionToken,
        customerLocationId,
        logoutAndClearCustomer,
        authenticated
    };
}

export default useCustomer;

export interface CustomerInterface {
    customerId: number | null,
    customerData: any
    contactListAccess: boolean,
    reloadCustomer: Function,
    clearCustomer: Function,
    addSessionIdAndCustomerIdRelation: Function,
    addCustomerIdAndShopIdRelation: Function,
    customerSessionToken: string | null,
    customerLocationId: number | null,
    logoutAndClearCustomer: Function,
    authenticated: boolean | null
}

export const CustomerInit: CustomerInterface = {
    customerId: null,
    customerData: null,
    contactListAccess: false,
    reloadCustomer: () => null,
    clearCustomer: () => null,
    addSessionIdAndCustomerIdRelation: () => null,
    addCustomerIdAndShopIdRelation: () => null,
    customerSessionToken: null,
    customerLocationId: null,
    logoutAndClearCustomer: () => null,
    authenticated: null
}